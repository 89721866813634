import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import InputCadastro from './inputForm';
import { useContext, useState } from 'react';
import { LeadContext } from '../providers/leadContext';
import { toast } from 'react-toastify';
import CupomAgropet from './cupom';
import RespFormorno from './resformorno'




const schema = yup.object({
 
 
  email: yup.string().email("Email inválido.").required("email obrigatório."),
})

const Formorno = () => {

  const {register, handleSubmit, watch, formState: { errors }, reset} = useForm({
    resolver:yupResolver(schema)
  });
   
  const [leadMorno, setLeadMorno] = useState(false)
  const [motivos, setMotivos ] = useState("")
  const [textMotivo, setTextMotivo ] = useState("")
  
  
  const motivoInputChange = (e) => {
    setMotivos(e.target.value)
    
  };

  const handleRegister = async ({ email, motivo}) => {
    // const lead = {
    //   nome,
    //   motivo:motivos,
    //   tel:telefone,
    //   email:email? email: "Email não informado",
    //   envCliente: false,
    //   observ:`Sem comentários`,
    // }
    
  
    //  if(await retornoUmLead(telefone)){
    //   toast.success(
    //   "Obrigado pelo cadastro, seus dados já se encontra em nossa base de dados."
    //   )
    //   reset()

    // } else {
    // await createLead("13", lead)
    
    if (motivos ==="const" ){
      setTextMotivo(
        "Para quem busca moradia, o Loteamento Duas Barras oferece uma localização centralizada, próxima a todas as comodidades que você precisa para viver bem. Você estará no coração da cidade, perto de escolas, supermercados e muito mais!"
      )
      
      setLeadMorno(true)
    } 
    if (motivos ==="invest" ){
      setTextMotivo("Investir no Loteamento Duas Barras é uma escolha inteligente! Nossa região tem uma alta possibilidade de valorização, garantindo retornos sólidos ao longo do tempo.")
      
      setLeadMorno(true)
    }  
    if (motivos ==="" ){
       toast.error("Selecione um motivo")
    }  
    
  }

    return(
      <>
      {leadMorno?
      <RespFormorno texto={textMotivo}/>
      :
      <form onSubmit={handleSubmit(handleRegister)} className='"transition ease-in-out delay-150
      bg-transparent 
      flex justify-center items-center flex-col
      text-primary-black
      text-1xl 
      rounded-[5%] 
      p-4 md:p-4 
      
      font-bold text-cente
      '>
        <h5 className="font-arimo mr-4 ml-4 text-white text-1xl font-bold text-center  
            animate-slide-down-05">
                   Descubra a qualidade e os benefícios dos nossos lotes 
                    </h5>
    
      <fieldset title='trdtyd'> 
      <div className="mb-3">
        <p className='text-white font-arimo text-center'>
          Qual o principal motivo da sua aquisição?
        </p>
         <select
            className='block mx-auto font-arimo text-center h-8'
            register= {register("motivo")}
            value={watch("motivo")} // Obtém o valor do campo usando watch
            onChange={motivoInputChange}
            >
              <option value="selec">Selecione</option>
            <option value="const">Construção</option>
            <option value="invest">Investimento</option>
            </select>
            <br/>
          
            {/* <p className='text-white font-arimo text-center '>
            Qual orçamento previsto para a aquisição?
        </p>
         <select
            className='block mx-auto  font-arimo h-8 text-center'
            register= {register("orcamento")}
            value={watch("orçamento")} // Obtém o valor do campo usando watch
            //onChange={motivoInputChange}
            >
            <option value="menor100">Menor que R$ 100 MIL</option>
            <option value="maior100">Maior que R$ 100 MIL</option>
            </select>
            <br/>
            <p className='text-white font-arimo text-center'>
            Como você prefere a localização do seu lote?
        </p>
         <select
            className='block mx-auto h-8 font-arimo text-center'
            register= {register("motivo")}
            value={watch("motivo")} // Obtém o valor do campo usando watch
            //onChange={motivoInputChange}
            >
            <option value="estrategico">Alto: na parte mais alta.</option>
            <option value="tranquilo">Baixo: na parte menos alta.</option></select>
            <br/> */}
         </div>
         
         <div className="mb-3 block mx-auto font-arimo text-white text-center ">
             <InputCadastro
             label="Email"
             id="email"
             type="text" 
             placeholder='exemplo@exemplo.com' 
             defaultValue="" 
             register= {register("email")} 
             className="form-control"
             aria-describedby="emailHelp"
             error={errors.email?.message}
             />
         </div>
      </fieldset>
       <br/>
       
       <button onClick={() =>   handleRegister} 
                                        className='
                                         text-white 
                                        rounded-full
                                        md: w-[230px]
                                        md: h-[40px]
                                        ml-8 m-4
                                        border-2 
                                        shadow-md
                                        hover:-translate-y-1
                                        hover:scale-110
                                        hover:bg-primary-focus duration-300'
                                        style={{ 
                                          background: 'linear-gradient(to right, #f47f15, #ec4000)'
                                          }}> 
                                        
                    Pesquisar
                </button>
     </form>
    }
     </>

    )
}
export default Formorno