import React, { useEffect, useRef, useState } from 'react';
import 'animate.css/animate.min.css';
import porta from '../assets/porta.png'
import BotaoZap from '../components/botaoZap';


const Beneficios = () => {

    const item = useRef();
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        function handleOpenScollAnimation() {
            const rect = item.current.getBoundingClientRect();

            if (rect.y - 300 <= (window.innerHeight || document.documentElement.clientHeight)) {
                setShowAnimation(true);
                window.removeEventListener('scroll', handleOpenScollAnimation);
            }
        }

        window.addEventListener('scroll', handleOpenScollAnimation);

        // Cleanup: Remove o listener quando o componente for desmontado
        return () => {
            window.removeEventListener('scroll', handleOpenScollAnimation);
        };
    }, []);

    useEffect(() => {
        if (showAnimation) {
            const timeoutId = setTimeout(() => {
                setShowAnimation(false);
            }, 700); 

            return () => clearTimeout(timeoutId);
        }
    }, [showAnimation]);


    return(

    <section  className='bg-img_bg_nuvem bg-cover bg-no-repeat' >
        <div className="flex flex-col sm:flex-row items-center justify-center">
          <div  className="max-w-[508px] "  >
            <div  className={`max-w-[508px] duration-1000 ml-4 sm:block 
                    `}>
                        
                        <p className={`text-center font-arimo p-2 text-3xl text-marron-escuro
                             animate__animated ${showAnimation ? 'animate-slide-Left-05' : ''}`}>
                          <strong>Loteamento Duas Barras</strong> 
                        </p>
                        <p className={`text-justify font-arimo p-2 text-2xl text-marron-escuro
                             animate__animated ${showAnimation ? 'animate-slide-Left-05' : ''}`}>
                          Lotes que são mais do que simples terrenos, 
                          são o começo de uma vida cheia de <strong>bem-estar e sofisticação</strong>. 
                        </p>
                        <p className={`text-justify font-arimo p-2 text-2xl text-marron-escuro
                             animate__animated ${showAnimation ? 'animate-slide-Left-05' : ''}`}>
                          Transforme seu Estilo de Vida conosco, onde a 
                          <strong> Qualidade de Vida</strong> se encontra com a <strong>Conveniência</strong> funcional. Conheça a 
                          <strong> Localização estratégica, o Design Sustentável</strong> e as experiências inspiradoras. 
                          
                        </p>
                        <div className='flex justify-center items-center'>                     
                        <BotaoZap texto="Saiba Mais" />
                </div>

                        <p className={`text-center font-arimo p-2 text-2xl text-marron-escuro
                             animate__animated ${showAnimation ? 'animate-slide-Left-05' : ''}`}>
                          Agende sua visita pelo Whatsapp agora e dê o primeiro passo para realizar seus sonhos!  
                        </p>
                        
                    </div>             
            </div>
        
            <div className={`max-w-[608px] l animate__animated ${showAnimation ? 'animate-slide-Rigth-08' : ''}`}>        
                  <img ref={item} src={porta} alt='logo'  />
            </div>    
                                  
        </div>
    </section>
    )
}

export default Beneficios