import { useContext, useEffect, useRef, useState } from 'react';
import 'animate.css/animate.min.css';
import  familia  from '../assets/Familia1.png'
import  folha  from '../assets/folha.png'
import  pag1 from '../assets/caixa.png'
import  logo  from '../assets/logo2.png'
import Formulario from '../components/fom';
import { BotaoContext } from '../providers/BotaoContext';
import BotaoAcao from '../components/botaoAcao';


const Promessas = () =>{

    const { showModal } = useContext(BotaoContext)

    const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 2000);

    // Limpe o timeout ao desmontar o componente para evitar vazamentos de memória
    return () => clearTimeout(timeout);
  }, []); // Executado apenas uma vez no carregamento do componente
   
    return(
        <header className="w-full h-full sm:h-[650px]  pt-1 bg-img_bg_fundo bg-no-repeat bg-center bg-cover">
        <div className="flex flex-col  sm:flex-row  bg-primary-gray mt-9  items-center justify-center ">
           
            <img  src={familia} alt='Familia' className={`relative  sm:left-[-100px] z-10  ${animate ? 'animate-pulse' : ''} `}  />

            <img  src={logo} alt='folha' className={`absolute top-0 center-0 z-20 ${animate ? 'animate-pulse' : ''}`} />
                             
            <div className={`max-w-[508px] flex flex-col items-center justify-center   bg-primary-gray duration-1000  ${animate ? 'animate-pulse' : ''}`}>
                
                    {/* <Formulario/> */}
              </div>
              <img  src={folha} alt='folha' className={`absolute center-0 left-0 z-20 hidden lg:block ${animate ? 'animate-pulse' : ''}`} />
              <img  src={pag1} alt='folha' className={`absolute center-0 right-10 z-20  hidden xl:block ${animate ? 'animate-pulse' : ''}`} />  
            
       </div >
       
    

</header>
    )
}
export default Promessas