import iconscond from '../assets/iconescond.png';
import BotaoAcao from '../components/botaoAcao';
import familia from '../assets/familia2.png'
import BotaoZap from '../components/botaoZap';


const Estrategias = () => {
     
    return(
        <>
    <section className='flex flex-col bg-img_bg_verde sm:h-[650px] rounded-3X1 sm:flex-row items-center justify-center'> 
    <div className='col  sm:p-1 mt-5 m'  >
                    <p className={`text-center font-arimo p-1 text-4xl text-white`}>
                          <strong>Conforto e bem estar para Sua Família:</strong> 
                        </p>
                        <p className={`text-justify font-arimo  p-2 text-2xl text-white`}>
                        
                        O Loteamento Duas Barras é um bairro planejado, 
                         que oferece infraestrutura completa para 
                        garantir o bem-estar da sua família. 
                        </p>

                        {/* <p className={`text-justify font-arimo p-2 text-2xl text-white`}>
                        O Loteamento Duas Barras Oferece Infraestrutura 
                        Completa para Garantir o Bem-Estar da Sua Família.   
                        </p> */}

                <img src={iconscond} alt='condominio' className="rounded-xl sm:ml-10 "/>
                       
                        
        <BotaoZap texto='Saiba mais'/>
    </div>
    <div  className='col sm:col-6' >
    <img src={familia} alt='logo' className="rounded-full sm:ml-8 sm:pl-8 "/>   
   
    </div>
       
    </section >  
  
   
    </>
    )
}

export default Estrategias