
import apto from"../assets/casa130.png"



const Casa130 = () => {

return(
    <>
       
     <section className='flex flex-col bg-img_bg_local   sm:h-[650px] items-center justify-center'> 
            
            <div className="flex flex-col sm:flex-row"  >
            
            {/* <div className="flex  justify-center text-center flex-col items-center sm:mr-12 text-marron-escuro text-arimo ">
                <p className="text-xl rounded-xl w-[300px]  bg-white opacity-90 p-4">
                    <strong>APARTAMENTO 57M2 </strong>
                </p>
                <p className="text-xl rounded-bl-xl w-[250px] rounded-br-xl bg-white opacity-70 p-3">
                  Localização Privilegiada
                
                </p>
                <p className="text-xl rounded-bl-xl rounded-br-xl w-[250px] bg-white opacity-70 p-3">
                  Conforto e Aconchego
                </p>
                </div>
                
                <div className="flex items-center text-marron-escuro ">
                
            </div>  */}
                <img src={apto} alt='apartamento' className=" border-4 border-zinc-white rounded-xl sm:m-4 "/>
                
            </div>

                     
            <p className={`mx-auto sm:w-1/2 text-center rounded-xl font-arimo sm: mt-10 text-2xl text-white bg-marron-escuro`}>
                              Visite nosso Stand e faça um Tour Virtual
                        </p>
                       

     </section>
    </>
)

} 

export default Casa130