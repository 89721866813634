import { Routes, Route } from "react-router-dom"
import Home from "../page/home"
import Privacidade from "../politica/privacidade"



const Rotas=() => {
    return(
            <>
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={'/politica'} element={<Privacidade/>} />
            </Routes>
            </>
    )
}

export default Rotas