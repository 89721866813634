
const BotaoZap = ({texto}) => {
   
   
    return(
        <>
         <div className="flex items-center justify-center md:pt-2 mt-0 ">
         <button id="zap" onClick={() =>  window.open("http://wa.me/5573998189251", "_black")} 
                                        className='
                                        bg-marron-escuro  text-white 
                                        rounded-full
                                        md: w-[230px]
                                        md: h-[50px]
                                         mx-5 my-3  
                                        border-2
                                        shadow-md
                                        hover:-translate-y-1
                                        hover:scale-110
                                        hover:bg-primary-focus duration-300'
                                        style={{ 
                                          background: 'linear-gradient(to right, #f47f15, #ec4000)'
                                          }}> 
                    {texto}
                </button>
          </div> 
        </>
    )
}
export default BotaoZap