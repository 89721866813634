
import casa from '../assets/quiosque.png'
import valores from '../assets/valores.png'
import BotaoZap from '../components/botaoZap'


const Valores = () => {

return(
    <>
       
     <section className=' flex flex-col bg-verde-medio sm:flex-row  sm:h-[650px] items-center justify-center'> 
     
           
            <div>
               <p className='text-center text-4xl text-white'><strong>Pagamento facilitado</strong></p>
               <p className='text-white' >Invista na melhor localização de Itabuna com Condições Facilitadas!</p>                          
            <img src={valores} alt='logo' className="rounded-xl sm:m-10 "/> 
            <div className='flex justify-center items-center'>                     

                 <BotaoZap texto="Saiba Mais"/>      
                </div>       

            </div>    
            <div className='flex flex-col sm:flex-row  items-center justify-center '>
                
            </div>
                <img src={casa} alt='logo' className=" sm:m-4 "/>

            

           

        

                       

     </section>
    </>
)

} 

export default Valores