const icon =({fig, texto}) =>{

    return(
       <div className="flex flex-row items-center pr-8">
        <img src={fig} alt={texto} />
        <p className="mt-4">{texto}</p>
       </div> 
    )

}

export default icon