import local from "../assets/local.png"
import supermercado from"../assets/Supermercado.png"
import shoping from"../assets/Shoping.png"
import farm from"../assets/Farmacia.png"
import acad from"../assets/Academia.png"
import rest from"../assets/Restaurante.png"
import est from"../assets/Estadio.png"


import Icon from "../components/icon"

const Local = () => {

return(
    <>
       
     <section className='flex flex-col-reverse sm:flex-row  sm:h-[650px] items-center justify-center'> 
     
            <div >
                <img src={local} alt='logo' className="rounded-xl  "/>
            </div>

            <div>
                        <p className={`text-center font-arimo p-1 text-4xl text-marron-escuro`}>
                          <strong>Localização Privilegiada</strong> 
                        </p>
                       
                        <p className={`text-justify font-arimo p-2 sm:ml-10 sm:mr-10 text-2xl text-marron-escuro`}>
                          Nossos lotes tem a melhor localização da cidade! 
                          
                        </p>

                        <p className={`text-justify font-arimo p-2 sm:ml-10 sm:mr-10 text-2xl text-marron-escuro`}>
                        Perto de tudo que você precisa:    
                        </p>
                        <div className="flex flex-row justify-center" >
                            <Icon fig={shoping} texto="Shopping"  />
                            <Icon fig={supermercado} texto="Supermercados" />
                            <Icon fig={farm} texto="Farmácias" />
                        </div>

                        <div className="flex flex-row justify-center" >
                            <Icon fig={acad} texto=" Academia"  />
                            <Icon fig={rest} texto=" Restaurantes" />
                            <Icon fig={est} texto="  Estádio" />
                        </div>
                       

                       

                        {/* <p className={`text-justify font-arimo p-2 sm:ml-10 mr-10 text-2xl textverde-escuro`}>
                         Nossa localização privilegiada em uma área pacífica e 
                         amigável proporciona um ambiente seguro para você e sua família. 
                        </p> */}

                        <p className={`mx-auto sm:w-1/2 text-center rounded-xl font-arimo p-2 sm: mt-10 text-1xl text-white bg-marron-escuro`}>
                         Endereço: Jardim Vitória, Góes Calmon, São Caetano | Itabuna-Ba
                        </p>
            </div>            

                       

     </section>
    </>
)

} 

export default Local