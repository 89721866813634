
import casal from '../assets/planta2.png'
import Formorno from '../components/formorno'


const Leadmorno = () => {

return(
    <>
       
     <section className=' flex flex-col sm:flex-row bg-img_bg_formorno sm:p-3 sm:h-[650px] items-center justify-center'> 
     
           
            <div>
                                           
                {/* <Formorno/>       */}

            </div>    
            <div className='flex flex-col sm:flex-row  items-center justify-center '>
               <img src={casal} alt='logo' className="rounded-xl sm:m-4 w-[75%] "/>
            </div>

           

        

                       

     </section>
    </>
)

} 

export default Leadmorno