import local from "../assets/local.png"
import casas from"../assets/casas.png"

const Imoveis = () => {

return(
    <>
       
     <section className='flex flex-col sm:flex-row bg-img_bg_casas sm:h-[650px] items-center justify-center'> 
     
           

            <div>
                        <p className={`text-center font-arimo sm: p-10 sm:mb-10 text-4xl text-marron-escuro`}>
                        <strong>Compre imóvel de acordo com sua preferência</strong> 
                        </p>
                                               
                        <p className={`text-center font-arimo p-2 rounded-r-full bg-verde-medio sm:ml-10 sm:mr-10 text-3xl text-white`}>
                          Apartamento 57m2   
                        </p>

                        <p className={`text-center font-arimo p-2 rounded-r-full bg-marron-escuro sm:ml-10 sm:mr-10 text-3xl text-white`}>
                          Casa 100m2   
                        </p>

                        <p className={`text-center font-arimo p-2 rounded-r-full bg-verde-medio sm:ml-10 sm:mr-10 text-3xl text-white`}>
                          Casa 130m2   
                        </p>

                        <p className={`text-center font-arimo p-2 rounded-r-full bg-marron-escuro sm:ml-10 sm:mr-10 text-3xl text-white`}>
                          Casa 200m2   
                        </p>

            </div>

            <div >
                <img src={casas} alt='logo' className="rounded-xl sm:m-4 "/>
            </div>          

                       

     </section>
    </>
)

} 

export default Imoveis