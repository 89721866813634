import pets from '../assets/folha.png'
import logo from '../assets/logo2.png'


const Rodape = () => {

    return (
        <>
         <div className='flex flex-col sm:flex-row items-center justify-center bg-img_bg_footer'>
                <div className='flex m-3'>
                    <img className="logo sm:mr-10 sm:pr-10" src={pets} alt="Cão e gato"/>  
                </div> 
                <div className='flex flex-col items-center justify-center'>
                    <div>
                    <img src={logo} alt='logo' className="rounded-xl sm:m-4 "/>
                    </div>
                    
                </div> 
                <div className='flex flex-col pl-7 ml-5'>
                    <div className='flex items-left justify-left'>        
                        <p onClick={() =>  window.open("https://www.instagram.com/duasbarraslot/", "_black")} 
                        className='p-1 text-verde-escuro cursor-pointer hover:scale-110  hover:text-primary-focus duration-300'>
                            Siga-nos no Instagram
                            </p>
                    </div>
                    <div className='flex items-left justify-left'>        
                        <p onClick={() =>  window.open("https://www.google.com.br/maps/dir//G%C3%B3es+Calmon,+Itabuna+-+BA,+45608-165/@-14.8064683,-39.3512333,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x739aa5278586a21:0x9029457123642765!2m2!1d-39.2688315!2d-14.8064829?entry=ttu")} 
                        className='p-1 text-verde-escuro cursor-pointer hover:scale-110  hover:text-primary-focus duration-300'>
                            Como chegar
                            </p>
                    </div>
                        <div className='flex items-left justify-left'>         
                            <p className='text-verde-escuro '>
                            Telefone: (73) 9 9818-9251
                            </p>
                        </div>

                    {/* <div className=' flex items-left justify-left'>
                        <img className='isnta' src={email} alt='email'/>
                        <p className='p-1 text-white'>contado@aliads.com.br</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Rodape